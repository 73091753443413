// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import '../components/quiz.css';
// import Swal from 'sweetalert2';
// import { useHistory } from 'react-router-dom';
// import parse from 'html-react-parser';
// import { useQuizResults } from '../components/QuizResultsContext';
// import { useLocation } from 'react-router-dom';

// const Chapter = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1;
//   const history = useHistory();
//   // const { addQuizResult } = useQuizResults();

//   const [startTime, setStartTime] = useState(null);
//   const [hasPushedResult, setHasPushedResult] = useState(false);
//   const [elapsedTime, setElapsedTime] = useState(0);

//   const location = useLocation();
//   const chapterId = location.state?.chapterId;
//   const additionalProp = location.state?.additionalProp;

//   const handleResultSubmit = (e) => {
//     e.preventDefault();
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//     setStartTime(new Date());
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const startQuiz = () => {
//     setStartTime(new Date());
//     axios
//     .get(` ${additionalProp} `)
//       .then(response => {
//         setIsLoaded(true);
//         const shuffledQuestions = response.data.sort(() => Math.random() - 0.5);
//         const selectedQuestions = shuffledQuestions.slice(0, 20);
//         setItems(selectedQuestions);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   };

//   useEffect(() => {
//     if (startTime && !hasPushedResult) {
//       const intervalId = setInterval(() => {
//         const now = new Date();
//         const elapsedTimeInSeconds = Math.floor((now - startTime) / 1000);
//         setElapsedTime(elapsedTimeInSeconds);
//       }, 1000);

//       return () => clearInterval(intervalId);
//     }
//   }, [startTime, hasPushedResult]);

//   const formatElapsedTime = (seconds) => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const remainingSeconds = seconds % 60;
//     return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
//   };

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correct_opt.toString()) {
//           return 'option wrong';
//         }
//       }
//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const handleShowResultClick = () => {
//     if (!hasPushedResult) {
//       const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//         const item = mergedItemsArray[index];
//         if (selectedOption === item.correct_opt.toString()) {
//           acc++;
//         }
//         return acc;
//       }, 0);

//       const totalQuestions = mergedItemsArray.length;
//       const currentTime = new Date();
//       const elapsedTimeInSeconds = Math.floor((currentTime - startTime) / 1000);
//       const score = count;
//       const percentage = (count / totalQuestions) * 100;

//       // Add the result to the context
//       const result = {
//         timeTaken: elapsedTimeInSeconds,
//         score: score,
//         percentage: percentage,
//       };

//       // Send the result to the backend API
//       axios.post('http://ab.geoneer.com.np/api/api/quiz-results/', result)
//         .then(response => {
//           console.log('Quiz result submitted to backend:', response.data);
//         })
//         .catch(error => {
//           console.error('Error submitting quiz result to backend:', error);
//         });

//       setShowResultDetails(false);

//       Swal.fire({
//         title: 'Result',
//         text: `You answered ${count} questions correctly!`,
//         icon: 'info',
//       }).then(() => {
//         history.push('');
//       });

//       setHasPushedResult(true);
//     }
//   };


//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
//       {!startTime && (
//         <button className="show-result-button" onClick={startQuiz}>
//           Start Quiz
//         </button>
//       )}

  
//       <h1>Chapter {chapterId}</h1>
//       <p></p>


//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       <div className="time-display">
//         {startTime && <p>{formatElapsedTime(elapsedTime)}</p>}
//       </div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//         <ul style={{ listStyle: 'none' }}>
//           {mergedItemsArray.map(item => (
//             <li key={item.id}>
//               <h6>
//                 <p ref={questionRef} style={{ textAlign: 'left' }}>
//                   Question {questionNumber++} : {parse(item.question)}
//                 </p>
//               </h6>
//               <ul style={{ listStyleType: 'none' }}>
//                 <li
//                   className={getOptionClassName(item.id, "a")}
//                   onClick={() => handleOptionClick(item.id, "a")}
//                   style={optionStyle}
//                 >
//                   <span className="option-text">{parse(item.option1)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, "b")}
//                   onClick={() => handleOptionClick(item.id, "b")}
//                   style={optionStyle}
//                 >
//                   <span className="option-text">{parse(item.option2)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, "c")}
//                   onClick={() => handleOptionClick(item.id, "c")}
//                   style={optionStyle}
//                 >
//                   <span className="option-text">{parse(item.option3)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, "d")}
//                   onClick={() => handleOptionClick(item.id, "d")}
//                   style={optionStyle}
//                 >
//                   <span className="option-text">{parse(item.option4)}</span>
//                 </li>
//               </ul>
//               {showResultDetails && (
//                 <div className="result-details">
//                   <p>Correct Option: {item.correct_opt}</p>
//                   <p>Explanation: {item.explanation}</p>
//                 </div>
//               )}
//             </li>
//           ))}

//           {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//             <button className="submit-button" onClick={handleResultSubmit}>
//               Submit
//             </button>
//           )}
//         </ul>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>
//           Show Result
//         </button>
//       )}
//     </div>
//   );
// };

// export default Chapter;





// import React, { useState, useEffect, useRef} from 'react';
// import axios from 'axios';
// import '../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// const Chapter = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const location = useLocation();
//   // const chapterId = location.state?.chapterId;
//   const additionalProp = location.state?.additionalProp;
//   const chapterName = location.state?.chapterName;

//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);
  

//   useEffect(() => {
//     axios
//       .get(` ${additionalProp} `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   },[additionalProp]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const handleShowResultClick = () => {
//     const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);

//     setShowResultDetails(true);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${count} questions correctly!`,
//       icon: 'info',
//     }).then(() => {
//       // Store quiz data in local storage
//       const quizData = {
//         date: new Date().toLocaleString(),
//         score: count,
//       };
//       localStorage.setItem('quizData', JSON.stringify(quizData));
//     });
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
      
    
//       <h6>Practice Questions for    <strong>"{chapterName}"</strong> </h6>
//       <br></br>

//       {console.log(chapterName)}

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//    <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {mergedItemsArray.map(item => (
//             <li key={item.id}>
//               <br></br>
// <h6>
//   <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//     <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//       Q {questionNumber++}:
//     </span>
//     <span style={{ flex: 1 }}>
//       {parse(item.question)}
//     </span>
//   </div>
// </h6>

// <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//   <li
//     className={getOptionClassName(item.id, "1")}
//     onClick={() => handleOptionClick(item.id, "1")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option1)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "2")}
//     onClick={() => handleOptionClick(item.id, "2")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option2)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "3")}
//     onClick={() => handleOptionClick(item.id, "3")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option3)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "4")}
//     onClick={() => handleOptionClick(item.id, "4")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option4)}</span>
//   </li>
// </ul>
// {showResultDetails && (
//                   <div className="result-details">
//                   <p>Correct Option: {item.correctOpt}</p>
//                   <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//   <p >Explanation: {parse(item.explanation)}</p>
// </div>
//                 </div>
// )}

           
//             </li>
//           ))}
//         </ul>
//       )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//       )}
//     </div>
//   );
// };

// export default Chapter;



import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import '../components/quiz.css';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext'; // Assuming you have AuthContext set up

const Chapter = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const questionRef = useRef(null);
  const [showResultButton, setShowResultButton] = useState(false);
  const [optionStyle, setOptionStyle] = useState({});
  const [showResultDetails, setShowResultDetails] = useState(false);
  const [disabledQuestions] = useState([]);
  let questionNumber = 1;

  const location = useLocation();
  const additionalProp = location.state?.additionalProp;
  const chapterName = location.state?.chapterName;

  const { authTokens } = useContext(AuthContext); // Extracting authTokens from AuthContext

  const handleShowResultClick = () => {
    const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
      const item = mergedItemsArray[index];
      if (selectedOption === item.correctOpt.toString()) {
        acc++;
      }
      return acc;
    }, 0);

    setShowResultDetails(true);
    setShowResultButton(true); // Show result button after calculating score

    // Display the result
    Swal.fire({
      title: 'Result',
      text: `You answered ${count} questions correctly!`,
      icon: 'info',
    });

    // Submit the result in the background without displaying a message
    handleResultSubmit(count);

    return count; // Return the score for further processing
  };

  const handleResultSubmit = async (score) => {
    if (!authTokens?.access) {
        console.error('Authentication credentials are not provided. Please log in again.');
        return;
    }

    const quizResultData = {
        chapter_name: chapterName,
        score: score,
    };

    try {
        const response = await axios.post(
            'https://auth.geoneer.com.np/api/quiz-results/',
            quizResultData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
            }
        );

        if (response.status !== 201 && response.status !== 200) {
            console.error('Failed to submit quiz results');
        }
    } catch (error) {
        console.error('Error submitting quiz results:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!showResultButton) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showResultButton]);

  useEffect(() => {
    axios
      .get(`${additionalProp}`)
      .then(response => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
  }, [additionalProp]);

  useEffect(() => {
    if (questionRef.current) {
      const questionWidth = questionRef.current.getBoundingClientRect().width;
      const optionStyle = {
        width: questionWidth + 'px',
      };
      setOptionStyle(optionStyle);
    }
  }, [items]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Detect common screenshot combinations
      if ((event.ctrlKey && event.key === 's') || // Ctrl + S
          (event.ctrlKey && event.key === 'p') || // Ctrl + P
          (event.metaKey && event.shiftKey && event.key === '4') || // Cmd + Shift + 4 on macOS
          (event.key === 'PrintScreen')) { // PrtScr button
        event.preventDefault(); // Prevent the default action
        window.location.href = 'http://www.example.com'; // Redirect to a different page
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  

  const mergedItems = items.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = item;
    } else {
      acc[item.id] = { ...acc[item.id], ...item };
    }
    return acc;
  }, {});

  const mergedItemsArray = Object.values(mergedItems);

  const handleOptionClick = (itemId, option) => {
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers(prevState => ({
        ...prevState,
        [itemId]: option.toString(),
      }));
    }
  };

  const getOptionClassName = (itemId, option) => {
    const item = mergedItemsArray.find(item => item.id === itemId);
    const selectedOption = selectedAnswers[itemId];

    if (selectedOption === option.toString()) {
      if (showResultButton) {
        if (selectedOption !== item.correctOpt.toString()) {
          return 'option wrong';
        }
      }
      return 'option selected';
    } else if (disabledQuestions.includes(itemId)) {
      return 'option disabled';
    } else {
      return 'option';
    }
  };

  const attendedQuestionsCount = Object.keys(selectedAnswers).length;

  return (
    <div className="quiz-container">
      <h6>Practice Questions for <strong>"{chapterName}"</strong></h6>
      <br />
      <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

      {error && <div>Error: {error.message}</div>}
      {!isLoaded && !error && <div>Loading...</div>}
      {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

      {isLoaded && mergedItemsArray.length > 0 && (
        <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
          {mergedItemsArray.map(item => (
            <li key={item.id}>
              <br />
              <h6>
                <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
                  <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                    Q {questionNumber++}:
                  </span>
                  <span style={{ flex: 1 }}>{parse(item.question)}</span>
                </div>
              </h6>

              <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
                <li className={getOptionClassName(item.id, "1")} onClick={() => handleOptionClick(item.id, "1")} style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}>
                  <span className="option-text">{parse(item.option1)}</span>
                </li>
                <li className={getOptionClassName(item.id, "2")} onClick={() => handleOptionClick(item.id, "2")} style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}>
                  <span className="option-text">{parse(item.option2)}</span>
                </li>
                <li className={getOptionClassName(item.id, "3")} onClick={() => handleOptionClick(item.id, "3")} style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}>
                  <span className="option-text">{parse(item.option3)}</span>
                </li>
                <li className={getOptionClassName(item.id, "4")} onClick={() => handleOptionClick(item.id, "4")} style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}>
                  <span className="option-text">{parse(item.option4)}</span>
                </li>
              </ul>

              {showResultDetails && (
                <div className="result-details">
                  <p>Correct Option: {item.correctOpt}</p>
                  <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
                    <p>Explanation: {parse(item.explanation)}</p>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}

      {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
        <button 
          className="submit-button" 
          onClick={handleShowResultClick} 
          style={{ display: showResultButton ? 'none' : 'block' }}
        >
          Submit Answers
        </button>
      )}

      {showResultDetails && showResultButton && (
        <button className="show-details-button" onClick={() => setShowResultDetails(!showResultDetails)}>
          {showResultDetails ? 'Hide' : 'Show'} Details
        </button>
      )}
    </div>
  );
};

export default Chapter;
