// import React, { useState, useContext } from 'react';
// import { Link} from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import reg from './images/reg.png';

// function Registerpage() {
//   const [email, setEmail] = useState('');
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [password2, setPassword2] = useState('');
//   const [course, setCourse] = useState('civil'); // Default to civil engineering
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState('');
  
//   const { registerUser } = useContext(AuthContext);

  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
//   };

//   const handlePaymentDetailsChange = (e) => {
//     setPaymentDetails(e.target.files[0]); // Handle image upload
//   };

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: '1rem' }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: '100%',
//                         height: 'auto',
//                         borderRadius: '1rem 0 0 1rem',
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: '#ff6219' }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome</span>
//                         </div>
//                         <h5
//                           className="fw-normal mb-3 pb-3"
//                           style={{ letterSpacing: 1 }}
//                         >
//                           Sign Up
//                         </h5>

//                         {/* Email */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             onChange={(e) => setEmail(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Username */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="username"
//                             className="form-control form-control-lg"
//                             placeholder="Your Name"
//                             onChange={(e) => setUsername(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password"
//                             onChange={(e) => setPassword(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Confirm Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password2"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             onChange={(e) => setPassword2(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Course Dropdown */}
//                         <div className="form-outline mb-4">
//                           <select
//                             id="course"
//                             className="form-control form-control-lg"
//                             onChange={(e) => setCourse(e.target.value)}
//                             required
//                           >
//                             <option value="civil">Civil NEC license</option>
//                             <option value="geomatics">Geomatics license</option>
//                           </select>
//                         </div>

//                         {/* Phone Number */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="phone_number"
//                             className="form-control form-control-lg"
//                             placeholder="Phone Number"
//                             maxLength="15"
//                             onChange={(e) => setPhoneNumber(e.target.value)}
//                           />
//                         </div>

//                         {/* Payment Details (Image Upload) */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             id="payment_details"
//                             className="form-control form-control-lg"
//                             accept="image/*"
//                             onChange={handlePaymentDetailsChange}
//                           />
//                         </div>

//                         {/* Submit Button */}
//                         <div className="pt-1 mb-4">
//                           <button
//                             className="btn btn-dark btn-lg btn-block"
//                             type="submit"
//                           >
//                             Register
//                           </button>
//                         </div>

//                         <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
//                           Already have an account?{' '}
//                           <Link to="/login" style={{ color: '#393f81' }}>
//                             Login Now
//                           </Link>
//                         </p>

//                         <a href="#!" className="small text-muted">
//                           Terms of use
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div
//           className="text-center p-3"
//           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//         >
//           © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Registerpage;


import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import reg from './images/reg.png';
import QRPopup from './QRPopup'; 

function Registerpage() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [course, setCourse] = useState('civil'); // Default to civil engineering
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to control the QR Popup


  const { registerUser } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
    setHasUnsavedChanges(false); // Reset unsaved changes after submission
  };

  const handlePaymentDetailsChange = (e) => {
    setPaymentDetails(e.target.files[0]); // Handle image upload
    setHasUnsavedChanges(true);
  };

  
  const handlePopupClose = () => {
    setShowPopup(false); // Close popup
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // Most browsers display a generic message
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <div>
      <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: '1rem' }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src={reg}
                      alt="registration form"
                      className="img-fluid"
                      style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '1rem 0 0 1rem',
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <i
                            className="fas fa-cubes fa-2x me-3"
                            style={{ color: '#ff6219' }}
                          />
                          <span className="h2 fw-bold mb-0">Welcome</span>
                        </div>
                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: 1 }}
                        >
                          Sign Up
                        </h5>

                        {/* Email */}
                        <div className="form-outline mb-4">
                          <input
                            type="email"
                            id="email"
                            className="form-control form-control-lg"
                            placeholder="Email Address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            required
                          />
                        </div>

                        {/* Username */}
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            id="username"
                            className="form-control form-control-lg"
                            placeholder="Your Name"
                            onChange={(e) => {
                              setUsername(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            required
                          />
                        </div>

                        {/* Password */}
                        <div className="form-outline mb-4">
                          <input
                            type="password"
                            id="password"
                            className="form-control form-control-lg"
                            placeholder="Password at least 8 character"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            required
                          />
                          <p>at least 8 character</p>
                        </div>

                        {/* Confirm Password */}
                        <div className="form-outline mb-4">
                          <input
                            type="password"
                            id="password2"
                            className="form-control form-control-lg"
                            placeholder="Confirm Password"
                            onChange={(e) => {
                              setPassword2(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            required
                          />
                        </div>

                        {/* Course Dropdown */}
                        <div className="form-outline mb-4">
                          <select
                            id="course"
                            className="form-control form-control-lg"
                            onChange={(e) => {
                              setCourse(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            required
                          >
                            <option value="civil">Civil NEC license</option>
                            <option value="geomatics">Geomatics license</option>
                          </select>
                        </div>

                        {/* Phone Number */}
                        <div className="form-outline mb-4">
                          <input
                            type="text"
                            id="phone_number"
                            className="form-control form-control-lg"
                            placeholder="Phone Number"
                            maxLength="15"
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                          />
                        </div>
                                              {/* Payment Popup Button */}
                        <div className="form-outline mb-4">
                          <button
                            type="button"
                            className="btn btn-success btn-lg btn-block"
                            onClick={() => setShowPopup(true)}
                          >
                            Click here for payment
                          </button>
                          {showPopup && <QRPopup onClose={handlePopupClose} />}
                        </div>

                        {/* Payment Details (Image Upload) */}
                        <div className="form-outline mb-4">
                          <input
                            type="file"
                            id="payment_details"
                            className="form-control form-control-lg"
                            accept="image/*"
                            onChange={handlePaymentDetailsChange}
                          />
                          <p>Attach the screen shot of the payment detail here</p>
                        </div>

                        {/* Submit Button */}
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
                          Already have an account?{' '}
                          <Link to="/login" style={{ color: '#393f81' }}>
                            Login Now
                          </Link>
                        </p>

                        <a href="#!" className="small text-muted">
                          Terms of use
                        </a>
                        <a href="#!" className="small text-muted">
                          Privacy policy
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="bg-light text-center text-lg-start">
        <div
          className="text-center p-3"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
        >
          © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
        </div>
      </footer>
    </div>
  );
}

export default Registerpage;
